/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React from 'react'

import { classes, style, stylesheet } from 'typestyle'
import { Box } from '../box/box.component'

export const Line = (props: {
  horiz?: boolean
  baseline?: string
  pad?: number
  className?: string
  dark?: boolean
}) => {
  const { className, horiz, pad = 0, dark } = props

  if (horiz) {
    return (
      <Box
        className={classes(
          STYLES.containerHoriz,
          dark ? STYLES.colorDark : STYLES.colorNormal,
          className,
        )}
        minWidth={1}
        maxWidth={1}
        minHeight={`calc(100% - ${pad * 2}px)`}
        maxHeight={`calc(100% - ${pad * 2}px)`}
      />
    )
  }

  return (
    <Box
      className={classes(
        STYLES.containerVert,
        dark ? STYLES.colorDark : STYLES.colorNormal,
        className,
      )}
      minWidth={`calc(100% - ${pad * 2}px)`}
      maxWidth={`calc(100% - ${pad * 2}px)`}
    />
  )
}

const STYLES = stylesheet({
  containerHoriz: {
    width: 1,
    display: 'inline',
  },
  containerVert: {
    height: 1,
    display: 'inline',
  },
  colorDark: {
    background: 'rgb(220,220,220)',
  },
  colorNormal: {
    background: 'rgb(234,234,234)',
  },
})
