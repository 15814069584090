import React from 'react'
import { important } from 'csx'
import { classes, keyframes, stylesheet } from 'typestyle'

/* Components =================================================================================== */
import { Box } from '../../../../__aeki__/components/box/box.component'

/* Styles ======================================================================================= */
import { createMediaQuery } from '../../../../__aeki__/components/style-class/style-class.utils'

export const WebScrollIcon = () => {
  return (
    <Box baseline="none" className={STYLES.container} absolute>
      <i className={classes('material-icons', STYLES.icon)}>arrow_downward</i>
    </Box>
  )
}

const keyframeArrowAnimation = keyframes({
  '0%': {
    transform: 'translateY(-14px)',
    opacity: 0,
  },
  to: {
    transform: 'translateY(14px)',
    opacity: 1,
  },
})

const STYLES = stylesheet({
  container: {
    bottom: 58,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 20,
  },
  icon: {
    animationName: keyframeArrowAnimation,
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    fontSize: important('24px'),
    $nest: {
      ...createMediaQuery.md({
        fontSize: important('24px'),
      }),
    },
  },
})
