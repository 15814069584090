import React from 'react'
import { classes, keyframes, style, stylesheet } from 'typestyle'

import { Box } from '../../../../__aeki__/components/box/box.component'

import { flex } from '../../../../__aeki__/styles/styleset/flex/flex'

export const WebImageSlider = (props: { src?: string; reverse?: boolean }) => {
  const { reverse, src } = props

  return (
    <Box
      style={{
        backgroundImage: `url("${src}")`,
      }}
      className={classes(
        STYLES.container,
        flex,
        style({
          animationName: reverse ? keyframeAnimateReverse : keyframeAnimate,
        }),
      )}
      minHeight={72}
      maxHeight={72}
      fullHeight
      spaceBetween
    />
  )
}

const keyframeAnimate = keyframes({
  from: {
    backgroundPosition: '0 0',
  },
  /*use negative width if you want it to flow right to left else and positive for left to right*/
  to: {
    backgroundPosition: '-10000px 0',
  },
})

const keyframeAnimateReverse = keyframes({
  from: {
    backgroundPosition: '0 0',
  },
  /*use negative width if you want it to flow right to left else and positive for left to right*/
  to: {
    backgroundPosition: '10000px 0',
  },
})

const STYLES = stylesheet({
  container: {
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: '0 0',
    animationDuration: '180s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    backgroundColor: 'rgba(0,0,0,0)',
  },
})
