import React, { useEffect, useRef } from 'react'
import { stylesheet } from 'typestyle'

export const WebVideoBackground = (props: {
  src: {
    desktop?: string
    mobile?: string
  }
}) => {
  const { desktop, mobile } = props.src || {}
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (videoRef.current) {
      ;(videoRef.current as any).play()
    }
  }, [videoRef.current])

  return (
    <video ref={videoRef} className={STYLES.video} autoPlay loop muted playsInline>
      <source type="video/mp4" src={desktop} media="screen and (min-width:800px)" />
      <source type="video/mp4" src={mobile} media="screen and (max-width:799px)" />
    </video>
  )
}

const STYLES = stylesheet({
  video: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    filter: 'opacity(0.7)',
  },
})
