import { style } from 'typestyle'

export const COLORS = {
  green: style({
    color: '#3c7775 !important',
  }),
  yellow: style({
    color: '#d2ba54 !important',
  }),
  darkGrey: style({
    color: 'rgb(80,80,80) !important',
  }),
  mediumGrey: style({
    color: '#767886 !important',
  }),
}

export const COLORS_BACKGROUND = {
  0: style({
    background: '#22483f !important',
  }),
  1: style({
    background: '#275a4d !important',
  }),
  2: style({
    background: '#2e6e5e !important',
  }),
  3: style({
    background: '#3a8571 !important',
  }),
  4: style({
    background: '#48a18a !important',
  }),
  5: style({
    background: '#46a18a !important',
  }),
  6: style({
    background: '#fee81b !important',
  }),
}
