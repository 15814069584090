import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { classes, keyframes, style } from 'typestyle'
import useValue from '../../../../__aeki__/hooks/use-value'
import { Box } from '../../../../__aeki__/components/box/box.component'
import { Button } from '../../../../__aeki__/components/button'
import { Line } from '../../../../__aeki__/components/line'
import { Text } from '../../../../__aeki__/components/text/text.component'
import { hidemdi } from '../../../../__aeki__/styles/styleset/hide/hidemdi'
import { mb16 } from '../../../../__aeki__/styles/styleset/margin/mb16'
import { mb8 } from '../../../../__aeki__/styles/styleset/margin/mb8'
import { mt24 } from '../../../../__aeki__/styles/styleset/margin/mt24'
import { mt40 } from '../../../../__aeki__/styles/styleset/margin/mt40'
import { mt8 } from '../../../../__aeki__/styles/styleset/margin/mt8'
import { pb24 } from '../../../../__aeki__/styles/styleset/padding/pb24'
import { pl80 } from '../../../../__aeki__/styles/styleset/padding/pl80'
import { w100mdi } from '../../../../__aeki__/styles/styleset/width/w100mdi'
import { createMediaQuery } from '../../../../__aeki__/components/style-class/style-class.utils'
import { w43 } from '../../../../__aeki__/styles/styleset/width/w43'
import { w57 } from '../../../../__aeki__/styles/styleset/width/w57'

const locale = {
  자세희보기: {
    en: 'Read more',
    ko: '자세히보기',
  },
}

const CollapsibleHeader = (props: any) => {
  const { open, onClick, isPlaying } = props
  const { loc = 'ko' } = useParams<{ loc: 'en' | 'ko' }>()

  return (
    <Box onClick={onClick} row>
      <Box className={classes(mt8, mb8)} row>
        <Box
          spaceBetween
          style={{
            cursor: 'pointer',
          }}
        >
          <Text size="lg" semiBold>
            {props.label}
          </Text>
          <i
            className={'material-icons'}
            style={{
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'all 0.2s ease-in-out',
            }}
          >
            keyboard_arrow_up
          </i>
        </Box>
      </Box>
      <Box
        className={classes(open && pb24)}
        row
        style={{
          height: open ? 'auto' : 0,
          transition: 'all 0.2s ease-in-out',
          overflow: 'hidden',
          opacity: open ? 1 : 0,
        }}
      >
        <Box
          style={{
            background: 'rgb(240,240,240)', // config.theme[0].baselines.primary.color.light,
            height: 4,
          }}
          fullWidth
          className={classes(open && mb16)}
          rounded
        >
          <Box
            className={style({
              background: '#3c7775', //config.theme[0].baselines.primary.color.dark,
              height: 4,
              animationName: isPlaying && open && keyframeProgress,
              animationDuration: '12s',
              animationTimingFunction: 'linear',
              animationPlayState: 'revert',
              $nest: {
                '&:hover': {
                  animationName: 'none',
                },
              },
            })}
            rounded
          />
        </Box>
        <Box
          baseline="background"
          className={style({
            backgroundImage: `url("${process.env.REACT_APP_DIGITAL_OCEAN_STORAGE_URL}${props.image}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            marginBottom: 16,
            display: 'none',
            $nest: {
              ...createMediaQuery.md({
                height: '40vw',
                display: 'initial',
              }),
              ...createMediaQuery.sm({
                height: '60vw',
                display: 'initial',
              }),
            },
          })}
          fullWidth
          rounded
        />
        <Text className={mb16} size="lg" readable>
          {props.description}
        </Text>
        <Box alignRight>
          <Link
            to={`${loc !== 'ko' ? `/${loc}` : ''}/a/services#${props.label.replace(/ /g, '_')}`}
          >
            <Button size="lg" baseline="primary" label={locale['자세희보기'][loc]} square />
          </Link>
        </Box>
      </Box>
      <Line dark />
    </Box>
  )
}

let intervalId: any = ''
export const WebCollapsible = (props: any) => {
  const { loc = 'ko' } = useParams<{ loc: 'en' | 'ko' }>()
  const [selected, $selected] = useValue(0)
  const [isPlaying, $isPlaying] = useValue(true)

  const handleClick = (index: number) => () => {
    $selected.set(index)
  }

  useEffect(() => {
    clearInterval(intervalId)
    intervalId = setInterval(() => {
      $selected.set((prev: any) => (prev + 1) % 5)
    }, 12000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    clearInterval(intervalId)
    if (isPlaying) {
      intervalId = setInterval(() => {
        $selected.set((prev: any) => (prev + 1) % 5)
      }, 12000)
    }
  }, [isPlaying])

  return (
    <Box flex className={mt24}>
      <Box
        className={classes(w43, w100mdi)}
        rounded
        row
        onMouseEnter={() => {
          $isPlaying.set(false)
        }}
        onMouseLeave={() => {
          $isPlaying.set(true)
        }}
      >
        {props.children}
        <Box className={props.children && mt40} row>
          {props.data.map((item: any, index: number) => {
            return (
              <CollapsibleHeader
                isPlaying={isPlaying}
                onClick={handleClick(index)}
                open={index === selected}
                key={index}
                label={item.label}
                description={item.description}
                image={item.image.replace('desktop', 'mobile')}
              />
            )
          })}
        </Box>
      </Box>
      <Box className={classes(w57, pl80, hidemdi)} alignCenterX alignCenterY relative>
        {props.data.map((item: any, key: any) => {
          return (
            <Box
              absolute
              key={key}
              baseline="background"
              style={{
                backgroundImage: `url("${process.env.REACT_APP_DIGITAL_OCEAN_STORAGE_URL}${item.image}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: key === selected ? 1 : 0,
                transform: key === selected ? 'transitionY(0px)' : 'transitionY(-20px)',
                transition: 'all 0.2s ease-in-out',
                width: '84%',
                height: '100%',
                right: 0,
              }}
              fullWidth
              fullHeight
              rounded
            />
          )
        })}
      </Box>
    </Box>
  )
}

const keyframeProgress = keyframes({
  '0%': {
    width: '0%',
  },
  '100%': {
    width: '100%',
  },
})
