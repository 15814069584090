import React, { useEffect } from 'react'
import { classes, style, stylesheet } from 'typestyle'
import { important } from 'csx'

import useValue from '../../../../__aeki__/hooks/use-value'

import { Box } from '../../../../__aeki__/components/box/box.component'
import { Text } from '../../../../__aeki__/components/text/text.component'
import { Title } from '../../../../__aeki__/components/text/title.component'

import { pl16 } from '../../../../__aeki__/styles/styleset/padding/pl16'
import { pr16 } from '../../../../__aeki__/styles/styleset/padding/pr16'
import { textCenter } from '../../../../__aeki__/styles/styleset/text-center/textCenter'
import { z2 } from '../../../../__aeki__/styles/styleset/z-index/z2'
import { mt20 } from '../../../../__aeki__/styles/styleset/margin/mt20'
import { mb24 } from '../../../../__aeki__/styles/styleset/margin/mb24'
import { Heading } from '../../../../__aeki__/components/text/heading.component'

export const HeroTitle = (props: any) => {
  const { textUpper, textLower, textLarge = true, textUpperFontSecondary, children } = props
  const [loaded, $loaded] = useValue(false)

  useEffect(() => {
    setTimeout(() => {
      $loaded.set(true)
    }, 300)
  }, [])

  return (
    <Box
      className={classes(STYLES.container, z2)}
      absolute
      centerAbsoluteY
      centerAbsoluteX
      fullWidth
      fullHeight
      alignCenterY
      alignCenterX
      row
    >
      <Title
        baseline="primary"
        bold
        size={textLarge ? 'lg' : 'md'}
        className={classes(
          textCenter,
          textLarge ? STYLES.textUpperInitial : STYLES.textUpperInitialMedium,
          loaded && STYLES.textUpperLoaded,
        )}
        as={<h3 />}
        secondary={textUpperFontSecondary}
        uppercase
      >
        {textUpper}
      </Title>
      <Box minWidth={200} baseline="none" alignCenterX className={classes(mb24, mt20)}>
        <hr className={classes(STYLES.hrInitial, loaded && STYLES.hrLoaded)} />
      </Box>
      <Box baseline="none" className={classes(pl16, pr16)}>
        <Heading
          className={classes(textCenter, STYLES.textLowerInitial, loaded && STYLES.textLowerLoaded)}
          size="xs"
          baseline="primary"
          as={<h4 />}
          readable
        >
          {textLower}
        </Heading>
      </Box>
      <div className={classes(STYLES.textLowerInitial, loaded && STYLES.textLowerLoaded)}>
        {children}
      </div>
    </Box>
  )
}

const STYLES = stylesheet({
  container: {
    background: 'rgba(0,0,0,0.4)',
  },
  textUpperInitial: {
    transform: 'translateY(16px)',
    opacity: 0,
  },
  textUpperInitialMedium: {
    transform: 'translateY(16px)',
    opacity: 0,
  },
  textUpperLoaded: {
    transition: '0.4s ease-in-out',
    transitionProperty: 'transform, opacity',
    transform: important('translateY(0px)'),
    opacity: important(1),
  },
  textLowerInitial: {
    transform: 'translateY(-16px)',
    opacity: 0,
  },
  textLowerLoaded: {
    transition: '0.4s ease-in-out',
    transitionProperty: 'transform, opacity',
    transform: important('translateY(0px)'),
    opacity: important(1),
  },
  hrInitial: {
    color: 'white',
    transition: 'width 0.4s ease-in-out',
    width: '0%',
    opacity: 0,
    border: '1px solid white',
    borderRadius: 2,
  },
  hrLoaded: {
    width: '100%',
    opacity: 1,
  },
})
