import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { classes } from 'typestyle'
import { Box } from '../../../__aeki__/components/box/box.component'
import { Button } from '../../../__aeki__/components/button'
import { Heading } from '../../../__aeki__/components/text/heading.component'
import { Title } from '../../../__aeki__/components/text/title.component'
import { ComponentBaselineType } from '../../../__aeki__/components/__core/component.types'
import { lh44i } from '../../../__aeki__/styles/styleset/line-height/lh44i'
import { mb16 } from '../../../__aeki__/styles/styleset/margin/mb16'
import { mb24 } from '../../../__aeki__/styles/styleset/margin/mb24'
import { mt36 } from '../../../__aeki__/styles/styleset/margin/mt36'
import { p8 } from '../../../__aeki__/styles/styleset/padding/p8'
import { pb12 } from '../../../__aeki__/styles/styleset/padding/pb12'
import { pt60 } from '../../../__aeki__/styles/styleset/padding/pt60'
import { textCenter } from '../../../__aeki__/styles/styleset/text-center/textCenter'

import { TEXTSTYLE } from './visible-text'

const locale = {
  보러가기: {
    en: 'Read more',
    ko: '자세히보기',
  },
}

export const EndPromote = (props: {
  title: string
  subtitle: string
  link: string
  linkLabel?: string
  baseline?: ComponentBaselineType
}) => {
  const { baseline = 'background' } = props
  const { loc = 'ko' } = useParams<{ loc: 'en' | 'ko' }>()

  return (
    <Box baseline={baseline} className={classes(p8, pt60, pb12)}>
      <Box baseline="none" maxWidth={600} centerAutoX row alignCenterX>
        <Title size="sm" as={<h1 />} className={classes(mb16, textCenter)} bold>
          {props.title}
        </Title>
        <Box className={classes(mb24, TEXTSTYLE.description, textCenter)} baseline="none">
          <Heading size="sm">{props.subtitle}</Heading>
        </Box>
        <Button
          label={props.linkLabel || locale.보러가기[loc]}
          baseline="primary"
          as={<Link to={props.link} />}
          size="lg"
          square
        />
        <Box maxWidth={'50%'} minWidth={'50%'} baseline="none" className={mt36}>
          <hr />
        </Box>
      </Box>
    </Box>
  )
}
