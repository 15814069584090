/**
 * Copyright 2021 AEKI <admin@aeki.dev>
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createTextComponent } from './text.component'

/* Styles ======================================================================================= */
import { titleClass } from './title.class'

export const Title = createTextComponent(titleClass)
