import React, { useEffect } from 'react'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { classes, style, stylesheet } from 'typestyle'
import useBoolean from '../../../__aeki__/hooks/use-boolean'
import useValue from '../../../__aeki__/hooks/use-value'
import { Box } from '../../../__aeki__/components/box/box.component'
import { createMediaQuery } from '../../../__aeki__/components/style-class/style-class.utils'
import { mb32smi } from '../../../__aeki__/styles/styleset/margin/mb32smi'
import { mb44mdi } from '../../../__aeki__/styles/styleset/margin/mb44mdi'
import { mb60 } from '../../../__aeki__/styles/styleset/margin/mb60'
import { mb8 } from '../../../__aeki__/styles/styleset/margin/mb8'
import { Text } from '../../../__aeki__/components/text/text.component'
import { Title } from '../../../__aeki__/components/text/title.component'
import { mt60 } from '../../../__aeki__/styles/styleset/margin/mt60'
import { mt40mdi } from '../../../__aeki__/styles/styleset/margin/mt40mdi'
import { mb64 } from '../../../__aeki__/styles/styleset/margin/mb64'
import { textCenter } from '../../../__aeki__/styles/styleset/text-center/textCenter'

export const VisibleSubtitle = (props: any) => {
  const [loaded, $loaded] = useBoolean(false)

  return (
    <ReactVisibilitySensor
      active={true}
      onChange={isVisible => {
        if (isVisible) {
          $loaded.set(true)
        }
      }}
    >
      {({ isVisible }) => {
        return (
          <Text
            className={classes(
              mb8,
              isVisible ? STYLES.textSubtitleVisible : STYLES.textSubtitleInitial,
            )}
            size="md"
            secondary
            uppercase
            bold
          >
            {props.children}
          </Text>
        )
      }}
    </ReactVisibilitySensor>
  )
}

export const Visible = (props: any) => {
  const [loaded, $loaded] = useBoolean(false)
  if (props.disableAnimation) {
    return (
      <Box baseline="none" row className={STYLES.boxVisible} {...props.boxProps}>
        {props.children}
      </Box>
    )
  }

  return (
    <ReactVisibilitySensor
      // active={!loaded}
      active={true}
      partialVisibility
      intervalDelay={props.delay}
      onChange={isVisible => {
        if (isVisible) {
          $loaded.set(true)
        }
      }}
    >
      {({ isVisible }) => {
        return (
          <Box
            baseline="none"
            row
            className={
              isVisible
                ? classes(
                    STYLES.boxVisible,
                    props.moveUp && STYLES.boxMoveUpVisible,
                    props.moveDown && STYLES.boxMoveDownVisible,
                  )
                : classes(
                    STYLES.boxInitial,
                    props.moveUp && STYLES.boxMoveUpInitial,
                    props.moveDown && STYLES.boxMoveDownInitial,
                  )
            }
            {...props.boxProps}
          >
            {props.children}
          </Box>
        )
      }}
    </ReactVisibilitySensor>
  )
}

export const VisibleCallback = (props: any) => {
  const [loaded, $loaded] = useBoolean(true)
  let test: any
  return (
    <ReactVisibilitySensor
      // active={!loaded}
      active={loaded}
      partialVisibility
      intervalDelay={props.delay}
      onChange={isVisible => {
        if (isVisible) {
          $loaded.set(false)
        } else {
          $loaded.set(true)
        }
      }}
    >
      {({ isVisible }) => {
        return props.children(!loaded)
      }}
    </ReactVisibilitySensor>
  )
}

export const VisibleSoft = (props: any) => {
  const [loaded, $loaded] = useBoolean(false)

  if (props.disableAnimation) {
    return (
      <Box className={classes(mb60, mb44mdi, mb32smi)} id={props.id} style={{ display: 'block' }}>
        {props.children}
      </Box>
    )
  }

  return (
    <ReactVisibilitySensor
      // active={!loaded}
      active={true}
      // partialVisibility
      // intervalDelay={props.delay}
      onChange={isVisible => {
        if (isVisible) {
          props?.onChange?.(isVisible)
          $loaded.set(true)
        }
      }}
    >
      {({ isVisible }) => {
        // console.log(props.children, isVisible)
        return (
          <Box
            className={classes(mb60, mb44mdi, mb32smi)}
            id={props.id}
            style={{ display: 'block' }}
          >
            {props.children}
          </Box>
        )
      }}
    </ReactVisibilitySensor>
  )
}

export const VisibleHR = (props: any) => {
  const [loaded, $loaded] = useBoolean(false)

  return (
    <ReactVisibilitySensor
      partialVisibility
      active={true}
      intervalDelay={0}
      onChange={isVisible => {
        if (isVisible) {
          $loaded.set(true)
        }
      }}
    >
      {({ isVisible }) => {
        return (
          <Box
            className={classes(
              mt60,
              mb64,
              mt40mdi,
              mb44mdi,
              style({ width: props.width || 280, alignItems: props.position ? 'flex-end' : '' }),
            )}
            baseline="none"
            // centerAbsoluteX={position === 'center'}
            alignCenterX={props.position === 'center'}
            row
          >
            <Box
              className={isVisible ? STYLES.lineVisible : STYLES.lineInitial}
              style={{
                border: 'none',
                height: 1,
                borderRadius: 2,
                background: props.color || 'black',
              }}
            />
          </Box>
        )
      }}
    </ReactVisibilitySensor>
  )
}

export const VisibleCounter = (props: any) => {
  const { position, disableCount, labelSize, subLabelSize, labelComponent, width } = props || {}
  const [loaded, $loaded] = useBoolean(false)

  const [count, $count] = useValue(0)
  let interval: any

  const Component = labelComponent === 'Text' ? Text : Title

  useEffect(() => {
    clearInterval(interval)
    if (disableCount) {
      $count.set(props.value)
    } else {
      if (loaded) {
        let _count = 0
        interval = setInterval(() => {
          if (_count < props.value) {
            _count += props.interval || 1
            if (props.float) {
              $count.set(parseInt(`${_count * 10}`) / 10)
            } else {
              $count.set(parseInt(`${_count}`))
            }
          } else {
            $count.set(Math.min(props.value, parseInt(`${_count * 10}`) / 10))
            clearInterval(interval)
          }
        }, 10)
      } else {
        $count.set(0)
        clearInterval(interval)
      }
    }

    return () => {
      clearInterval(interval)
    }
  }, [loaded])

  return (
    <ReactVisibilitySensor
      active={true}
      scrollCheck={true}
      intervalDelay={100}
      onChange={isVisible => {
        if (isVisible) {
          $loaded.set(true)
        } else {
          $loaded.set(false)
        }
      }}
    >
      {({ isVisible }) => {
        return (
          <Box
            row
            baseline="none"
            alignCenterX
            className={isVisible ? STYLES.boxVisible : STYLES.boxInitial}
            style={{ width: width || 120 }}
          >
            <Component size={labelSize || 'xl'} semiBold crank sub={props.subValue}>
              {props.float ? (count % 1 === 0 ? `${count}.0` : count) : count}
            </Component>
            <Text size={labelSize || 'md'} className={textCenter}>
              {props.label}
            </Text>
          </Box>
        )
      }}
    </ReactVisibilitySensor>
  )
}

export const TEXTSTYLE = stylesheet({
  subtitle: {
    fontSize: '16px !important',
    $nest: {
      // ...createMediaQuery.md({
      //   fontSize: '18px !important',
      // }),
      // ...createMediaQuery.sm({
      //   fontSize: '17px !important',
      // }),
      // ...createMediaQuery.xs({
      //   fontSize: '16px !important',
      // }),
    },
  },
  content: {
    fontSize: '38px !important',
    $nest: {
      ...createMediaQuery.md({
        fontSize: '36px !important',
      }),
      ...createMediaQuery.sm({
        fontSize: '34px !important',
      }),
      ...createMediaQuery.xs({
        fontSize: '32px !important',
      }),
    },
  },
  description: {
    display: 'inline-block',
    fontSize: '20px !important',
    $nest: {
      ...createMediaQuery.md({
        fontSize: '19px !important',
      }),
      ...createMediaQuery.sm({
        fontSize: '18px !important',
      }),
      ...createMediaQuery.xs({
        fontSize: '17px !important',
      }),
    },
  },
})

/* Styles ======================================================================================= */
const STYLES = stylesheet({
  textSubtitleInitial: {
    transform: 'translateY(12px)',
    opacity: 0,
  },
  textSubtitleVisible: {
    transition: '0.4s ease-in-out',
    transitionProperty: 'transform, opacity',
    // transitionDelay: '2s',
    opacity: 1,
    transform: 'translateY(0px)',
  },

  boxInitial: {
    opacity: 0,
    transition: 'opacity 500ms ease-in-out !important',
  },

  boxVisible: {
    opacity: 1,
    transition: 'opacity 500ms ease-in-out !important',
  },

  boxMoveUpInitial: {
    opacity: 0,
    marginLeft: -16,
    transition: '500ms ease-in-out !important',
    transitionProperty: 'opacity, margin-left',
  },

  boxMoveUpVisible: {
    opacity: 1,
    marginLeft: 0,
    transition: '500ms ease-in-out !important',
    transitionProperty: 'opacity, margin-left',
  },

  boxMoveDownInitial: {
    opacity: 0,
    transform: 'translateX(16px)',
    transition: '500ms ease-in-out !important',
    transitionProperty: 'opacity, transform',
  },

  boxMoveDownVisible: {
    opacity: 1,
    transform: 'translateX(0px)',
    transition: '500ms ease-in-out !important',
    transitionProperty: 'opacity, transform',
  },

  lineInitial: {
    width: '0%',
    opacity: 0,
    transition: 'width 500ms ease-in-out !important',
  },
  lineVisible: {
    width: '100%',
    opacity: 1,
    transition: 'width 500ms ease-in-out !important',
  },
})
