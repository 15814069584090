import React, { FC } from 'react'
import { classes, stylesheet } from 'typestyle'
import { Link, useParams } from 'react-router-dom'

/* Components =================================================================================== */
import AppLayout, { MAX_WIDTH } from './layout'
import { Box } from '../../__aeki__/components/box/box.component'
import { Button } from '../../__aeki__/components/button'
import { FooterContent } from '../../components/footer'
import { Heading } from '../../__aeki__/components/text/heading.component'
import { Text } from '../../__aeki__/components/text/text.component'
import { Visible, VisibleCounter, VisibleHR, VisibleSubtitle } from './components/visible-text'
import { WebScrollIcon } from './components/web-scroll-icon'
import { WebVideoBackground } from './components/web-video-background'
import { Title } from '../../__aeki__/components/text/title.component'
import { WebImageSlider } from './components/web-client-image-slider'
import { WebCollapsible } from './components/web-collapsible'
import { HeroTitle } from './components/web-hero-title'
import { EndPromote } from './components/end-promote'

/* Styles ======================================================================================= */
import { mb24 } from '../../__aeki__/styles/styleset/margin/mb24'
import { ml4 } from '../../__aeki__/styles/styleset/margin/ml4'
import { mr4 } from '../../__aeki__/styles/styleset/margin/mr4'
import { mt20 } from '../../__aeki__/styles/styleset/margin/mt20'
import { p12 } from '../../__aeki__/styles/styleset/padding/p12'
import { pt60 } from '../../__aeki__/styles/styleset/padding/pt60'
import { COLORS } from './colors'
import { hidesmi } from '../../__aeki__/styles/styleset/hide/hidesmi'
import { hidei } from '../../__aeki__/styles/styleset/hide/hidei'
import { flexsmi } from '../../__aeki__/styles/styleset/flex/flexsmi'
import { ml8 } from '../../__aeki__/styles/styleset/margin/ml8'
import { mr8 } from '../../__aeki__/styles/styleset/margin/mr8'
import { mb40 } from '../../__aeki__/styles/styleset/margin/mb40'
import { pr8 } from '../../__aeki__/styles/styleset/padding/pr8'
import { pl8 } from '../../__aeki__/styles/styleset/padding/pl8'
import { pt40 } from '../../__aeki__/styles/styleset/padding/pt40'
import { pt24 } from '../../__aeki__/styles/styleset/padding/pt24'
import { mb12 } from '../../__aeki__/styles/styleset/margin/mb12'
import { hide } from '../../__aeki__/styles/styleset/hide/hide'
import { showmdi } from '../../__aeki__/styles/styleset/show/showmdi'
import { pb92 } from '../../__aeki__/styles/styleset/padding/pb92'
import { pl20 } from '../../__aeki__/styles/styleset/padding/pl20'
import { pr20 } from '../../__aeki__/styles/styleset/padding/pr20'
import { mt24 } from '../../__aeki__/styles/styleset/margin/mt24'
import locale from './locale'

/* <Home /> ===================================================================================== */
const Home: FC<any> = () => {
  const { loc = 'ko' } = useParams<{ loc: 'en' | 'ko' }>()

  const CONTENT = {
    services: [
      {
        label: locale.services1[loc],
        image: '/images/desktop/samil-lsp-construction.jpg',
        description: locale.services1Desc[loc],
        link: `${loc !== 'ko' ? `/${loc}` : ''}/a/services#건자재물류`,
      },
      {
        label: locale.services2[loc],
        image: '/images/desktop/samil-lsp-general.jpg',
        description: locale.services2Desc[loc],
        link: `${loc !== 'ko' ? `/${loc}` : ''}/a/services#일반B2B물류`,
      },
      {
        label: locale.services3[loc],
        image: '/images/desktop/samil-lsp-parts.jpg',
        description: locale.services3Desc[loc],
        link: `${loc !== 'ko' ? `/${loc}` : ''}/a/services#자동차물류`,
      },
      {
        label: locale.services4[loc],
        image: '/images/desktop/samil-lsp-special.jpg',
        description: locale.services4Desc[loc],
        link: `${loc !== 'ko' ? `/${loc}` : ''}/a/services#특수화물물류`,
      },
      {
        label: locale.services5[loc],
        image: '/images/desktop/samil-lsp-export.jpg',
        description: locale.services5Desc[loc],
        link: `${loc !== 'ko' ? `/${loc}` : ''}/a/services#국제물류`,
      },
      {
        label: locale.services6[loc],
        image: '/images/desktop/samil-lsp-warehouse.jpg',
        description: locale.services6Desc[loc],
        link: `${loc !== 'ko' ? `/${loc}` : ''}/a/services#3PL/4PL`,
      },
    ],
  }

  return (
    <AppLayout.render
      head={{
        title: locale.title[loc],
        description: locale.description[loc],
        mainImage: process.env.REACT_APP_PROJECT_MAIN_IMAGE,
      }}
      content={
        <>
          <Box className={STYLES.container} baseline="surface" row flex>
            {/* 히로 --------------------------------------------------------------------------- */}
            <Box
              className={STYLES.containerHero}
              relative
              fullWidth
              shadow
              baseline="secondary"
              minHeight={800}
            >
              <WebVideoBackground
                src={{
                  desktop: '/videos/samil-lsp-intro.mp4',
                  mobile: '/videos/samil-lsp-intro-mobile.mp4',
                }}
              />
              <HeroTitle
                textUpper={
                  <>
                    Samil Logistics
                    <br /> Service Provider
                  </>
                }
                textUpperFontSecondary={true}
                textLower={
                  <>
                    {locale.slogan1[loc]}
                    <br className={classes(hide, showmdi)} /> {locale.slogan2[loc]}
                  </>
                }
              >
                <Box className={mt24} baseline="none" flex>
                  <Link className={mr8} to={`${loc !== 'ko' ? `/${loc}` : ''}/a/contact-us`}>
                    <Button label={locale.contactUs[loc]} baseline="primary" size="lg" />
                  </Link>
                  <Link to={`${loc !== 'ko' ? `/${loc}` : ''}/a/services`}>
                    <Button
                      label={locale.seeServices[loc]}
                      size="lg"
                      border
                      baseline="surface"
                      alt
                    />
                  </Link>
                </Box>
              </HeroTitle>
              <WebScrollIcon />
            </Box>
            {/* ---------------------------------------------------------------------------- 히로 */}

            {/* 회사소개 ------------------------------------------------------------------------- */}
            <Box baseline="surface" className={classes(pt60, pb92, STYLES.containerRounded)}>
              <Box baseline="none" className={classes(pl20, pr20)} maxWidth={1400} centerAutoX row>
                <VisibleSubtitle>Logistics Experts</VisibleSubtitle>
              </Box>
              <Box baseline="none" className={classes(pl20, pr20)} maxWidth={1400} centerAutoX row>
                <Visible>
                  <Box maxWidth={loc === 'en' ? 500 : 428}>
                    <Title className={classes(mb12)} size="sm" as={<h2 />} bold readable>
                      {locale.section1Title[loc]}
                    </Title>
                  </Box>
                </Visible>
                <Visible delay={300}>
                  <Box baseline="none" maxWidth={loc === 'en' ? 500 : 440}>
                    <Heading size="sm" style={{ color: '#767886' }} readable>
                      {locale.section1Desc[loc]}
                    </Heading>
                  </Box>
                </Visible>
                <Box alignCenterX flex>
                  <VisibleHR position="center" />
                </Box>
                <Box className={classes(pl20, pr20)} alignCenterX>
                  <Box
                    className={classes(hidesmi, mb24)}
                    baseline="none"
                    minWidth={600}
                    flex
                    spaceBetween
                    style={{ flexWrap: 'wrap' }}
                  >
                    <VisibleCounter
                      label={locale['매출액'][loc]}
                      value={locale['매출액Value'][loc]}
                      float={loc !== 'ko'}
                      subValue={locale['매출액Unit'][loc]}
                      interval={locale['매출액Increment'][loc]}
                    />
                    <VisibleCounter
                      float={true}
                      label={locale['월운송량'][loc]}
                      value={locale['월운송량Value'][loc]}
                      subValue={locale['월운송량Unit'][loc]}
                      interval={locale['월운송량Increment'][loc]}
                    />
                    <VisibleCounter
                      label={locale['월거래량'][loc]}
                      value={locale['월거래량Value'][loc]}
                      subValue={locale['월거래량Unit'][loc]}
                      interval={locale['월거래량Increment'][loc]}
                    />
                    <VisibleCounter
                      label={locale['소속차량'][loc]}
                      value={locale['소속차량Value'][loc]}
                      interval={locale['소속차량Increment'][loc]}
                    />
                  </Box>
                </Box>
                <Box className={classes(hidei, flexsmi, mb40)} baseline="none" flex alignCenterX>
                  <VisibleCounter
                    label={locale['매출액'][loc]}
                    value={locale['매출액Value'][loc]}
                    float={loc !== 'kr'}
                    subValue={locale['매출액Unit'][loc]}
                    interval={locale['매출액Increment'][loc]}
                  />
                  <Box className={classes(ml8, mr8, mt20, mb40)} />
                  <VisibleCounter
                    float={true}
                    label={locale['월운송량'][loc]}
                    value={locale['월운송량Value'][loc]}
                    subValue={locale['월운송량Unit'][loc]}
                    interval={locale['월운송량Increment'][loc]}
                  />
                </Box>
                <Box className={classes(hidei, flexsmi, mb40)} baseline="none" flex alignCenterX>
                  <VisibleCounter
                    label={locale['월거래량'][loc]}
                    value={locale['월거래량Value'][loc]}
                    subValue={locale['월거래량Unit'][loc]}
                    interval={locale['월거래량Increment'][loc]}
                  />
                  <Box className={classes(ml8, mr8)} />
                  <VisibleCounter
                    label={locale['소속차량'][loc]}
                    value={locale['소속차량Value'][loc]}
                    interval={locale['소속차량Increment'][loc]}
                  />
                </Box>
                <Box alignRight>
                  <Text size="xs">* {locale['2022년도기준'][loc]}</Text>
                </Box>
              </Box>
              <Box
                className={classes(pt40, pl20, pr20)}
                baseline="none"
                maxWidth={1400}
                centerAutoX
                flex
                alignRight
              >
                <Link to={`${loc !== 'ko' ? `/${loc}` : ''}/a/company`}>
                  <Button label={locale.seeMore[loc]} square baseline="primary" />
                </Link>
              </Box>
            </Box>
            {/* ------------------------------------------------------------------------- 회사소개 */}

            {/* 거래처 --------------------------------------------------------------------------- */}
            {/* classes(ml8, mr8) */}
            <Box baseline="background" className={classes(p12, pt60, pb92)}>
              <Box baseline="none" className={classes(pl8, pr8)} maxWidth={1400} centerAutoX row>
                <VisibleSubtitle>Reliable Partnership</VisibleSubtitle>
                <Visible>
                  <Box baseline="none" maxWidth={loc === 'en' ? 590 : 428}>
                    <Title className={classes(mb12)} size="sm" as={<h2 />} bold>
                      {locale.section2Title[loc]}
                    </Title>
                  </Box>
                </Visible>
                <Visible delay={300}>
                  <Box baseline="none" maxWidth={loc === 'en' ? 700 : 428}>
                    <Heading size="sm" style={{ color: '#767886' }} readable>
                      {locale.section2Desc[loc]}
                    </Heading>
                  </Box>
                </Visible>
                <VisibleHR />
                <Box flex className={mb24} baseline="none" flexWrap>
                  <Text size="xl" bold className={classes(ml4, mr4, COLORS.green)}>
                    <Visible>{locale.반도체설비[loc]}</Visible>
                  </Text>
                  <Text size="xl" bold className={classes(ml4, mr4, COLORS.darkGrey)}>
                    <Visible delay={400}>{locale.자동차차부품[loc]}</Visible>
                  </Text>
                  <Text size="xl" bold className={classes(ml4, mr4, COLORS.darkGrey)}>
                    <Visible delay={600}>{locale.건설자재[loc]}</Visible>
                  </Text>
                  <Text size="xl" bold className={classes(ml4, mr4, COLORS.mediumGrey)}>
                    <Visible delay={800}>{locale.수출입품[loc]}</Visible>
                  </Text>
                  <Text size="xl" bold className={classes(ml4, mr4, COLORS.mediumGrey)}>
                    <Visible delay={1000}>{locale.특수화물[loc]}</Visible>
                  </Text>
                </Box>
              </Box>
              <WebImageSlider src={'/images/samil-lsp-clients-v3.png'} />
              <Box
                className={classes(pt24, pl8, pr8)}
                baseline="none"
                maxWidth={1400}
                centerAutoX
                flex
                alignRight
              >
                <Link to={`${loc !== 'ko' ? `/${loc}` : ''}/a/company#${locale.고객사현황[loc]}`}>
                  <Button label={locale.seeMore[loc]} square baseline="primary" />
                </Link>
              </Box>
            </Box>
            {/* --------------------------------------------------------------------------- 거래처 */}

            {/* 솔루션 --------------------------------------------------------------------------- */}
            <Box baseline="surface" className={classes(p12, pt60, pb92)}>
              <Box className={classes(pl8, pr8)} baseline="none" maxWidth={1400} centerAutoX row>
                <WebCollapsible data={CONTENT.services}>
                  <VisibleSubtitle>Know How & Know What</VisibleSubtitle>
                  <Visible>
                    <Title className={classes(mb24)} size="sm" as={<h2 />} bold>
                      {locale.section3Title[loc]}
                    </Title>
                  </Visible>
                  <Visible delay={300}>
                    <Box baseline="none">
                      <Heading size="sm" style={{ color: '#767886' }}>
                        {locale.section3Desc[loc]}
                      </Heading>
                    </Box>
                  </Visible>
                </WebCollapsible>
              </Box>
            </Box>
            {/* --------------------------------------------------------------------------- 솔루션 */}

            <EndPromote
              title={locale.homeCTA[loc]}
              subtitle={locale.homeCTADesc[loc]}
              link={`${loc !== 'ko' ? `/${loc}` : ''}/a/contact-us`}
              linkLabel={locale.contactUs[loc]}
              baseline="background"
            />
            <Box className={STYLES.containerFooter} baseline="background">
              <FooterContent baseline="background" />
            </Box>
          </Box>
        </>
      }
    />
  )
}

/* Styles ======================================================================================= */
const STYLES = stylesheet({
  container: {
    width: '100%',
  },
  containerInner: {
    maxWidth: MAX_WIDTH,
    padding: '0 12px 24px 12px',
    margin: '0 auto',
  },
  containerHero: {
    overflow: 'hidden',
    marginTop: -160,
    height: '50vh',
  },
  containerFooter: {
    padding: '40px 4px 12px 4px',
  },
  containerRounded: {
    borderRadius: 20,
    marginTop: -20,
    zIndex: 2,
  },
  textInitial: {
    transform: 'translateY(16px)',
    opacity: 0,
  },
  textVisible: {
    transition: 'all 0.2s ease-in-out',
    transitionProperty: 'transform',
    opacity: 1,
    transform: 'translateY(0px)',
  },
})

export default Home
