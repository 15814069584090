const years = new Date().getFullYear() - 1987

export default {
  contactUs: {
    ko: '문의하기',
    en: 'Contact us',
  },
  seeServices: {
    ko: '사업영역 보기',
    en: 'See our services',
  },
  seeMore: {
    ko: '자세히 보기',
    en: 'Read more',
  },
  title: {
    ko: '최적화된 물류 서비스 - 삼일로지스틱스',
    en: 'Optimized Logistics Services - Samil Logistics',
  },
  description: {
    ko:
      '일반화물, 특수화물, 벌크화물, 건설화물, 중량물/장재물, 설비 반입/반출, 부품운송, 창고보관, 포워딩, 물류대행 등 다양한 화물 특성에 최적화된 물류 서비스를 제공합니다.',
    en:
      'We provide logistics services optimized for various cargo characteristics such as general cargo, special cargo, bulk cargo, construction cargo, heavy cargo/lumber, equipment import/export, parts transportation, warehouse storage, forwarding, logistics agency, etc.',
  },
  slogan1: {
    ko: '고객사의 소중한 자산을 안전하고',
    en: 'We transport your valuable assets safely and',
  },
  slogan2: {
    ko: '신속하게 목적지까지 책임집니다.',
    en: 'quickly to your destination.',
  },

  services1: {
    ko: '건자재물류',
    en: 'Construction Materials Logistics',
  },
  services2: {
    ko: '일반B2B물류',
    en: 'General B2B Logistics',
  },
  services3: {
    ko: '자동차물류',
    en: 'Automotive Logistics',
  },
  services4: {
    ko: '특수화물물류',
    en: 'Specialized Logistics',
  },
  services5: {
    ko: '국제물류',
    en: 'International Logistics',
  },
  services6: {
    ko: '3PL/4PL',
    en: '3PL/4PL',
  },

  services1Desc: {
    ko:
      '오랜 건설자재 물류 운영경험을 바탕으로 건설원자재, 가설재, 파일, 골재 및 시멘트를 건설현장까지 안정적이고 신속/정확하게 운송',
    en:
      'Based on long-term construction material logistics experience, we transport construction materials such as construction materials, building materials, files, aggregates and cement to construction sites in a stable, fast and accurate manner.',
  },
  services2Desc: {
    ko:
      '전국 주요 지역에 형성된 운송 네트워크와 다양한 산업군에서의 물류운영 KNOW-HOW를 기반으로 최적의 기업간 수송/배송 서비스 제공',
    en:
      'Based on the transportation network formed in major regions of the country and the logistics Operations KNOW-HOW in various industries, we provide optimal inter-company transportation/delivery services.',
  },
  services3Desc: {
    ko:
      '자동차물류 전담팀을 통해 완성차 생산과정에 필요한 부품을 생산라인으로 적시에 효율적으로 공급되도록 운송 솔루션 제공',
    en:
      'Through the dedicated team for automotive logistics, we provide transportation solutions to supply parts required for the production process of finished vehicles to the production line in a timely and efficient manner.',
  },
  services4Desc: {
    ko:
      '특수화물운송 전담팀을 통한 중량물/장재물/대형화물의 특성과 운송환경 및 상하차 환경 등을 고려한 체계화된 운송설계와 안전관리팀을 통한 안전관리까지 최적의 운송 서비스 제공',
    en:
      'Through the dedicated team for specialized cargo transportation, we provide optimal transportation services from systematic transportation design considering the characteristics of heavy cargo/lumber/large cargo and transportation environment and loading/unloading environment to safety management through the safety management team.',
  },
  services5Desc: {
    ko:
      '내륙운송과 연계하여 수출입 화물의 국내외운송(해상/항공/육상), 보관, 하역, 통관까지 ONE-STOP 포워딩 서비스 제공',
    en:
      'In conjunction with inland transportation, we provide ONE-STOP forwarding services for domestic and international transportation (maritime/air/land), storage, stevedoring and customs clearance of export and import cargo.',
  },
  services6Desc: {
    ko:
      '고객사 특성에 맞춘 입/출고, 보관, 물류 대행 및 위탁 서비스를 제공하며 유통, 가공, 포장 등 고객 요구에 맞는 맞춤 물류서비스 제공',
    en:
      'We provide customized logistics services tailored to the characteristics of customers, such as inbound/outbound, storage, logistics agency and consignment services, and distribution, processing, packaging, etc.',
  },

  section1Title: {
    ko: '고객사와 함께 고민하며 성장해온 물류파트너',
    en: 'A logistics partner who has grown with customers',
  },
  section1Desc: {
    ko: `${years}년간 물류 전문 기업으로서 지속적으로 성장을 거듭해온 (주)삼일로지스틱스 입니다.`,
    en: `Samil Logistics has been growing continuously as a logistics specialist company for ${years} years.`,
  },

  section2Title: {
    ko: '삼일로지스틱스와 함께 해온 다양한 산업군의 고객사',
    en: 'Customers in various industries who have been with Samil Logistics',
  },
  section2Desc: {
    ko:
      '체계적 운행실적 관리를 통한 데이터 관리를 기반으로 고객사의 특성에 맞춘 최적의 물류 솔루션을 제공합니다.',
    en:
      'Based on data management through systematic Operations performance management, we provide optimal logistics solutions tailored to the characteristics of customers.',
  },

  section3Title: {
    ko: '삼일로지스틱스의 풍부한 경험과 전문성',
    en: "Samil Logistics's rich experience and expertise",
  },
  section3Desc: {
    ko: '최상의 물류 실행력과 운영으로 운송효율을 극대화 합니다.',
    en: 'We maximize transportation efficiency with the best logistics execution and operation.',
  },
  homeCTA: {
    ko: '업무컨설팅 안내',
    en: 'Business Consulting',
  },
  homeCTADesc: {
    ko: '각 분야의 전문 담당자가 바로 대응하여 드립니다.',
    en: "Each field's specialist will respond immediately.",
  },
  매출액: {
    ko: '매출액 (원)',
    en: 'Revenue (KRW)',
  },
  매출액Value: {
    ko: 460,
    en: 46,
  },
  매출액Unit: {
    ko: '억',
    en: 'b',
  },
  매출액Increment: {
    ko: 5,
    en: 0.5,
  },
  월운송량: {
    ko: '월운송량 (대)',
    en: 'Monthly Volume',
  },
  월운송량Value: {
    ko: 3.7,
    en: 37,
  },
  월운송량Unit: {
    ko: '만',
    en: 'k',
  },
  월운송량Increment: {
    ko: 0.05,
    en: 0.5,
  },
  월거래량: {
    ko: '월거래량 (t)',
    en: 'Monthly Transaction (t)',
  },
  월거래량Value: {
    ko: 53,
    en: 530,
  },
  월거래량Unit: {
    ko: '만',
    en: 'k',
  },
  월거래량Increment: {
    ko: 0.6,
    en: 6,
  },
  소속차량: {
    ko: '소속차량 (대)',
    en: 'Vehicles',
  },
  소속차량Value: {
    ko: 470,
    en: 470,
  },
  소속차량Increment: {
    ko: 4.5714285714,
    en: 4.5714285714,
  },
  '2022년도기준': {
    ko: '2023년도 기준',
    en: 'As of 2023',
  },
  반도체설비: {
    ko: '반도체설비',
    en: 'Semiconductor',
  },
  자동차차부품: {
    ko: '자동차부품',
    en: 'Automotive Parts',
  },
  건설자재: {
    ko: '건설자재',
    en: 'Construction',
  },
  수출입품: {
    ko: '수출입품',
    en: 'Import/Export Goods',
  },
  특수화물: {
    ko: '특수화물',
    en: 'Specialized Cargo',
  },
  고객사현황: {
    ko: '고객사 현황',
    en: 'Customers',
  },
}
